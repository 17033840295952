import {
	Dispatch,
	SetStateAction,
	createContext,
	Fragment,
	ReactNode,
	useState,
} from "react"

// Router
import { NavLink as RouterNavLink } from "@/lib/router"

// Assets
import logo from "@/assets/icons/brand/logo.svg"

// UI
import { classNames } from "@/lib/classnames"
import { WelcomeText } from "@/components/WelcomeText"
import { Dialog, Transition } from "@headlessui/react"

// Navigation
import { UserMenu, UserAndRole, Logo } from "@/components/navigation/UserMenu"
import { MainNavigation } from "@/components/navigation/MainNavigation"
import { Footer } from "@/components/navigation/Footer"

// Animations
import { motion } from "@/lib/animations"

// Icons
import { FiChevronLeft, FiChevronsRight } from "@/lib/icons"
import { MenuIcon } from "@/components/MenuIcon"

// Translations
import { useTrans } from "@/i18n"

// Misc
import { sendEvent } from "@/lib/analytics"

// Feature flags
import { useFeatureFlags } from "@/context/user"
import { WebappInstallButton } from "@/components/banners/WebappInstallButton"

// Types
interface MainLayoutProps {
	children?: ReactNode
}

export const MainLayoutContext = createContext<{
	isMobileMenuOpen: boolean
	isDesktopMenuOpen: boolean
	setMobileMenuOpen: Dispatch<SetStateAction<boolean>>
	setIsDesktopMenuClosed: Dispatch<SetStateAction<boolean>>
}>(null!)

/**
 * MainLayout
 *
 * @param param0
 * @returns
 */
export function MainLayout({ children }: MainLayoutProps) {
	// State
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
	const [isDesktopMenuOpen, setIsDesktopMenuClosed] = useState(true)

	// Translate
	const t = useTrans()

	// Context
	const { getFeatureFlagValue } = useFeatureFlags()

	function setMobileMenuOpenProxy(
		context: string = "mobilemenuiconontoggle",
	) {
		const nextValue = !isMobileMenuOpen
		sendEvent("layout", context, {
			label: nextValue ? "open" : "close",
		})
		setMobileMenuOpen(nextValue)
	}

	function setIsDesktopMenuClosedProxy() {
		const nextValue = !isDesktopMenuOpen
		sendEvent("layout", "desktopmenuicon", {
			label: nextValue ? "open" : "close",
		})
		setIsDesktopMenuClosed(nextValue)
	}

	return (
		<MainLayoutContext.Provider
			value={{
				isMobileMenuOpen,
				isDesktopMenuOpen,
				setMobileMenuOpen,
				setIsDesktopMenuClosed,
			}}
		>
			<div className="h-full min-h-full bg-gray-50 md:flex md:h-auto">
				{/* sidebar: desktop menu, a.k.a. the left nav on md: viewports and above */}
				<motion.div
					className="hidden min-h-full flex-shrink-0 bg-white text-black md:block"
					initial={{ width: 256 }}
					animate={{ width: isDesktopMenuOpen ? 256 : 40 }}
					transition={{
						duration: 0.4,
						ease: [0.22, 0.65, 0.275, 1],
					}}
				>
					<div className="sticky top-0 flex h-screen w-[256px] flex-col overflow-y-auto">
						<div
							className={classNames(
								"bg-primary-500 flex h-14 flex-shrink-0 items-center shadow-[0px_1px_3px_rgba(0,0,0,0.25)] 2xl:h-16",
								"pl-2",
							)}
						>
							{isDesktopMenuOpen === true && <Logo />}

							{/* menu icon for collapsble nav on desktop */}
							{getFeatureFlagValue(
								"ENABLE_MENU_COLLAPSE_ON_DESKTOP",
							) === true && (
								<button
									onClick={setIsDesktopMenuClosedProxy}
									className="hidden h-full items-center justify-center whitespace-nowrap hover:bg-gray-50 md:flex"
								>
									{isDesktopMenuOpen === true ? (
										<FiChevronLeft
											aria-hidden="true"
											className="h-4 w-4 text-gray-500"
										/>
									) : (
										<FiChevronsRight
											aria-hidden="true"
											className="h-4 w-4 text-gray-500"
										/>
									)}
								</button>
							)}
						</div>

						{/** Main nav */}
						<MainNavigation />
					</div>
				</motion.div>
				{/* mobile nav */}
				<Transition.Root show={isMobileMenuOpen} as={Fragment}>
					<Dialog
						as="div"
						className="md:hidden"
						onClose={() =>
							setMobileMenuOpenProxy("mobilemenuclose")
						}
					>
						<div className="fixed inset-0 z-40 flex h-full">
							<Transition.Child
								as={Fragment}
								enter="transition-opacity ease-linear duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="transition-opacity ease-linear duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
							</Transition.Child>
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<div className="relative flex w-full max-w-[256px] flex-1 flex-col bg-white sm:max-w-sm">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute right-0 top-1 -mr-14 p-1">
											<button
												type="button"
												className="flex h-12 w-12 items-center justify-center"
												onClick={() =>
													setMobileMenuOpenProxy(
														"mobilemenuiconclose",
													)
												}
											>
												<MenuIcon
													className="h-6 w-6 text-white"
													aria-hidden="true"
													isOpen={false}
												/>
												<span className="sr-only">
													{t(
														"common.navigation.close_sidebar.sr_text",
													)}
												</span>
											</button>
										</div>
									</Transition.Child>
									<div className="flex h-screen flex-1 flex-col overflow-y-auto pt-5">
										<div className="pl-2">
											<Logo />
										</div>
										<div className="flex-1">
											<MainNavigation />
										</div>
										<div className="flex-shrink-0 space-y-3 border-t border-gray-200 pb-4 pt-4">
											<UserMenu />
										</div>
									</div>
								</div>
							</Transition.Child>
							<div
								className="w-14 flex-shrink-0"
								aria-hidden="true"
							>
								{/* Dummy element to force sidebar to shrink to fit close icon */}
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				{/* "padding top" here is to offset the top bar on mobile */}
				<div className="flex min-h-full w-full flex-grow flex-col pt-14 md:max-w-[calc(100%_-_256px)] md:pt-0">
					<div className="fixed top-0 z-30 grid h-14 w-full grid-cols-[auto_auto] justify-between gap-3 bg-white shadow-[0px_1px_3px_rgba(0,0,0,0.125)] md:static md:grid-cols-[1fr_auto] md:px-4 2xl:h-16">
						{/* Welcome text component */}
						<WelcomeText />

						{/* Logo */}
						<div className="flex items-center md:hidden">
							<RouterNavLink to="/" className="px-4">
								<img
									src={logo}
									alt={t("common.layout.title")}
									className="h-auto w-8 md:w-6"
								/>
							</RouterNavLink>
						</div>

						<div className="order-2 flex h-full items-center justify-center md:order-1 md:hidden">
							<WebappInstallButton
								className={"block md:hidden"}
							/>

							{/* mobile nav menu icon */}
							<button
								type="button"
								className="w-14 px-3"
								onClick={() => setMobileMenuOpenProxy()}
								data-testid="navigation.open_sidebar"
							>
								<span className="sr-only">
									{t(
										"common.navigation.open_sidebar.sr_text",
									)}
								</span>
								<MenuIcon
									isOpen={true}
									aria-hidden="true"
									className="h-3 w-6"
								/>
							</button>
						</div>

						{/* user profile icon */}
						<div className="ml-auto hidden h-full items-center gap-3 md:flex md:gap-2">
							<UserMenu />
							<UserAndRole className="text-md hidden text-right text-gray-400 md:text-left" />
						</div>
					</div>

					{/** Content */}
					<div className="flex h-full w-full flex-grow flex-col">
						{children}
					</div>

					{/** Footer */}
					<div className="flex-shrink">
						<Footer />
					</div>
				</div>
			</div>
		</MainLayoutContext.Provider>
	)
}
