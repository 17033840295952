import { useEffect } from "react"

// Debug tools
import { mountVercelToolbar } from "@vercel/toolbar"

// Router
import { Pages } from "@/misc/pages"

// UI
import { classNames } from "@/lib/classnames"
import { Link } from "@/components/Anchor"
import { Heading } from "@/components/Typography"
import { Menu, Transition } from "@headlessui/react"

// Icons
import { LogoutIcon } from "@/lib/icons"
import { FiUser } from "@/lib/icons"

// Animations
import { getTransitionPopoverProps } from "@/lib/animations"

import { UserRoles } from "@/misc/constants"

// Translations
import { useTrans, Trans } from "@/i18n"
import { useLang } from "@/context/lang"
import { configs, Languages } from "@/i18n/config"

// Misc
import { useAuth } from "@/context/auth"
import { useToasts } from "@/context/toasts"
import { sendEvent } from "@/lib/analytics"

// Assets
import BrandLogo from "@/assets/icons/brand/logo.svg?react"

// Context
import { useCurrentUserInfo, useCurrentUserRoles } from "@/context/user"

// Env variables
import { API_URL, VERCEL_ENV } from "@/lib/env"

/**
 * UserMenu
 * @returns
 */
export function UserMenu() {
	const t = useTrans()
	const auth = useAuth()
	const toasts = useToasts()
	const { hasOneOfRoles } = useCurrentUserRoles()
	const { config, lang, setLang } = useLang()

	// On Mount, check for admin permissions
	useEffect(() => {
		if (hasOneOfRoles(UserRoles.admin)) {
			// Show vercel toolbar
			if (VERCEL_ENV !== "production") {
				mountVercelToolbar()
			}
		}
	}, [hasOneOfRoles])

	return (
		<Menu as="div" className="relative order-1 md:order-2">
			{({ open }) => (
				<>
					<Menu.Button
						className="flex px-2 md:px-0"
						onClick={() => {
							sendEvent("layout", "usermenu_icon_onclick")
						}}
					>
						<div
							className={classNames(
								"relative block overflow-hidden rounded-[50%] border-2 border-gray-400 bg-white",
								"mr-3 h-6 w-6 md:mr-0 md:h-8 md:w-8", // Sizes responsive
							)}
						>
							<span className="sr-only">
								{t("common.layout.open_user_menu")}
							</span>
							<UserIcon className="absolute -bottom-1 left-[50%] h-[95%] w-[95%] -translate-x-1/2 fill-gray-400 text-gray-400" />
						</div>

						<UserAndRole className="text-md block truncate text-left text-gray-400 md:hidden" />
					</Menu.Button>
					<Transition {...getTransitionPopoverProps()} show={open}>
						<Menu.Items
							static
							className={classNames(
								"right-0 z-50 mt-2 w-full origin-top-right rounded-md  pb-1 pt-1 focus:outline-none",
								"md:absolute md:w-48 md:bg-white md:shadow-lg md:ring-1 md:ring-black md:ring-opacity-5", // Desktop UI
							)}
						>
							<UserAndRole
								className={classNames(
									"px-4 py-2 text-right text-sm text-gray-600 md:text-left",
									// seperates the menu.items from the button
									"border-b border-b-gray-200",
									"hidden md:block", // Show on desktop
								)}
							/>

							{/* Menu items TODO: Make active class work! */}
							<>
								<Menu.Item>
									{({ active }) => (
										<Link
											href={
												Pages.SettingsProfilePersonalDetails
											}
											className={classNames(
												active && "bg-gray-100",
												"flex items-center px-4 py-2 text-sm text-gray-700",
											)}
											onClick={() => {
												sendEvent(
													"layout",
													"usermenu_item_onclick",
													{
														label: t(
															"common.layout.quick_menu.your_profile",
														),
													},
												)
											}}
										>
											{t(
												"common.layout.quick_menu.your_profile",
											)}
										</Link>
									)}
								</Menu.Item>

								{/** Language selector */}
								<Menu.Item disabled>
									{({ active }) => (
										<div
											className={classNames(
												active && "bg-gray-100",
												"relative flex items-center px-4 py-2 text-sm text-gray-700",
											)}
										>
											{t(
												"common:common.layout.quick_menu.language",
												{
													lang: config.display,
												},
											)}
											<select
												className="absolute left-0 top-0 h-full w-full cursor-pointer p-0 opacity-0"
												onChange={(evt) =>
													setLang(
														evt.currentTarget
															.value as Languages,
													)
												}
												value={lang}
												data-testid="language-selector"
											>
												{Object.values(configs).map(
													(config) => (
														<option
															value={config.path}
															key={config.path}
														>
															{config.display}
														</option>
													),
												)}
											</select>
										</div>
									)}
								</Menu.Item>

								{/** Admin items */}
								{hasOneOfRoles(UserRoles.admin) && (
									<>
										{/** App information */}
										<Menu.Item>
											{({ active }) => (
												<Link
													href={Pages.Info}
													className={classNames(
														active && "bg-gray-100",
														"flex items-center px-4 py-2 text-sm text-gray-700",
													)}
													onClick={() => {
														sendEvent(
															"layout",
															"usermenu_item_onclick",
															{
																label: "App information",
															},
														)
													}}
												>
													App information
												</Link>
											)}
										</Menu.Item>

										<Menu.Item>
											{({ active }) => (
												<p
													onClick={() => {
														sendEvent(
															"layout",
															"usermenu_item_onclick",
															{
																label: "Admin panel",
															},
														)
														// Redirect to admin panel
														window.location.href = `${API_URL}admin/`
													}}
													className={classNames(
														active && "bg-gray-100",
														"flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700",
													)}
												>
													Admin panel
												</p>
											)}
										</Menu.Item>
									</>
								)}

								{/** Logout */}
								<Menu.Item>
									{({ active }) => (
										<p
											onClick={() => {
												sendEvent(
													"layout",
													"usermenu_item_onclick",
													{
														label: t(
															"common.layout.quick_menu.sign_out",
														),
													},
												)
												// Try logout
												try {
													auth.logOut()
												} catch (e) {
													console.error(
														"Could not log out",
														e,
													)
													toasts.addToast({
														id: "common.layout.quick_menu.sign_out",
														text: "Failed to log out, try again later.",
													})
												}
											}}
											className={classNames(
												active && "bg-gray-100",
												"flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700",
											)}
										>
											<LogoutIcon
												className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
												aria-hidden="true"
											/>
											{t(
												"common.layout.quick_menu.sign_out",
											)}
										</p>
									)}
								</Menu.Item>
							</>
						</Menu.Items>
					</Transition>
				</>
			)}
		</Menu>
	)
}

export function UserIcon({ className = "" }) {
	return (
		<>
			<FiUser
				className={classNames(
					className,
					"h-[95%] w-[95%] fill-gray-400 text-gray-400",
				)}
			/>
		</>
	)
}

export function UserAndRole({ className = "" }) {
	const { first_name, last_name } = useCurrentUserInfo()

	return (
		<p className={classNames(className)}>
			{first_name} {last_name}
		</p>
	)
}

export function Logo() {
	return (
		<Heading
			as="h2"
			styleAs="h5"
			className="flex h-full w-full items-center truncate pl-1 text-black md:px-4"
		>
			<a href={API_URL} className="flex">
				<BrandLogo className="mr-2 h-6 w-6 brightness-0 grayscale" />
				<Trans
					ns="common"
					i18nKey="common.layout.title"
					components={{
						thin: <span className="font-medium" />,
					}}
				/>
			</a>
		</Heading>
	)
}
