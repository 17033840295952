// React
import {
	useMemo,
	ReactNode,
	ComponentType,
	useContext,
	createContext,
} from "react"

// DateTime
import { DateTime, DEFAULT_TIMEZONE } from "@/lib/dates"
import { apiDateFormat } from "@/misc/constants"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Utils
import { getShareNumberRange } from "@/misc/helpers"

// GraphQL
import { useFiscalOverviewByYearQuery } from "@/api/graphql"

// UI
import { CardBody } from "@/components/Card"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/Tooltip"

// Misc
import { useTableMetaData } from "@/hooks/useTableMetaData"
import { isWholeNumber } from "@/lib/math"

// Environment variables
import { API_URL } from "@/lib/env"

// Icons
import { FiDownload } from "@/lib/icons"

// Types
export type FiscalOverviewByYearContextType =
	| { type: "custom"; from: string; to: string }
	| { type: "year"; year: string }

export const FiscalOverviewByYearContext =
	createContext<FiscalOverviewByYearContextType>(null!)

function useData() {
	return useFiscalOverviewByYearQueryWithYear()
}

function useFiscalOverviewByYearQueryWithYear(
	options = { keepPreviousData: false },
) {
	const mode = useContext(FiscalOverviewByYearContext)

	const start: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.from).toFormat(apiDateFormat)
		}

		// we need to get the date in NL, so we can set the timezone to Amsterdam
		// then we get the start of the year, and then the backend expects the date in UTC
		// note: i had to set millisecond: 0 otherwise it was coming out in the iso timestamp
		return DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.set({
				year: parseInt(mode.year),
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	const end: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.to).toFormat(apiDateFormat)
		}

		// we can safely use the beginning of next year since the end date is exlusive
		return DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.set({
				year: parseInt(mode.year) + 1,
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	return useFiscalOverviewByYearQuery(
		{
			start,
			end,
		},
		options,
	)
}

// Types
type Data = {
	projectName?: string
	sharesAtStart?: number
	shareNumbersAtEnd: string
	valueAtStart?: number
	certificateUrl?: string
}

type Columns<Data> = Array<{
	title: string
	sort?: string
	accessor: (data: Data) => ReactNode
	Footer?: ComponentType<{ rows: Array<Data> }>
	className?: string
	component?: (props: { payment: Data }) => ReactNode
}>

/**
 * FiscalOverviewByYearTable
 * @returns
 */
export function FinancialOverviewTable() {
	const { formatCurrency } = useLang()
	const t = useTrans("investments")
	const { setSort, sort } = useTableMetaData()
	const { data, isPreviousData } = useData()

	const rows = useMemo(() => {
		if (!data?.me?.investment_projects) {
			return []
		}
		return data.me.investment_projects?.results?.map((project) => {
			return {
				projectId: project?.id ?? "",
				projectName: project?.name ?? "",
				sharesAtStart: project?.start?.total_shares ?? undefined,
				valueAtStart: project?.start?.total_investment_value
					? parseFloat(project?.start?.total_investment_value)
					: undefined,
				shareNumbersAtEnd:
					getShareNumberRange(
						project?.end?.shares?.map((share) =>
							Number(share?.share_number),
						),
					) ?? undefined,
				certificateUrl: `${API_URL}api/investor/solar-share-certificate/${project?.id}`,
			}
		})
	}, [data?.me?.investment_projects])

	const columns: Columns<Data> = [
		{
			title: t("investments.fiscal.table_heading.project_name"),
			accessor: (data) => data.projectName,
		},
		{
			title: "AANTAL ZONNEDELEN",
			accessor: (data) =>
				data.sharesAtStart
					? isWholeNumber(data.sharesAtStart)
						? data.sharesAtStart
						: Number(data.sharesAtStart ?? 0).toFixed(2)
					: "-",
		},
		{
			title: "OORSPRONKELIJKE WAARDE ",
			accessor: () => "",
		},
		{
			title: "HUIDIGE WAARDE",
			accessor: (data) =>
				data.valueAtStart
					? formatCurrency(data.valueAtStart ?? 0)
					: "-",
		},
		{
			title: t(
				"investments.fiscal.table_heading.share_numbers_at_end_of_year",
			),
			accessor: (data) => (
				<Tooltip
					content={`Download certificaat voor obligatienummers: ${data.shareNumbersAtEnd}`}
				>
					<a
						href={data.certificateUrl}
						className="flex text-sm underline hover:text-gray-500"
					>
						<FiDownload className="mr-2" />
						<span className="flex-1">{data.shareNumbersAtEnd}</span>
					</a>
				</Tooltip>
			),
		},
	]

	return (
		<>
			<Table className="min-w-[48rem] lg:min-w-0">
				{/* table header */}
				<TableHead>
					<tr role="row">
						{columns.map((header) => {
							const isSorted =
								header.sort && sort?.endsWith(header.sort)
							const isSortedDesc =
								isSorted && sort?.startsWith("-")

							return (
								<TableHeading
									as="th"
									key={header.title}
									colSpan={1}
									variant={
										header.sort ? "sortable" : "static"
									}
									role="columnheader"
									title={header.title}
									isSorted={Boolean(isSorted)}
									isSortedDesc={Boolean(isSortedDesc)}
									onClick={() => {
										if (!header.sort) {
											return
										}

										if (!isSorted && !isSortedDesc) {
											setSort(`-${header.sort}`)
										} else if (isSortedDesc) {
											setSort(header.sort)
										} else {
											setSort("")
										}
									}}
								>
									{header.title}
								</TableHeading>
							)
						})}
					</tr>
				</TableHead>
				{/* table body and table cells */}
				<TableBody
					role="rowgroup"
					className={isPreviousData ? "opacity-25" : ""}
					data-testid="tablebody"
				>
					{rows?.map(({ projectId, ...row }, index) => {
						return (
							<TableRowCell
								key={projectId}
								isOdd={index % 2 === 0}
								role="row"
								data-testid={`tablerow-${projectId}`}
							>
								{columns.map((column) => {
									return (
										<TableDataCell
											key={column.title}
											className="break-word whitespace-pre-wrap"
										>
											{column.accessor(row)}
										</TableDataCell>
									)
								})}
							</TableRowCell>
						)
					})}
				</TableBody>
			</Table>
			{rows?.length === 0 && (
				<CardBody>
					<TableEmptyState />
				</CardBody>
			)}
		</>
	)
}
const TableEmptyState = () => {
	const t = useTrans("investments")

	return (
		<div className="space-y-4 p-10 text-center">
			<Heading as="h2" styleAs="h5">
				{t("investments.fiscal.no_results.title")}
			</Heading>
			<p className="text-gray-500">
				{t("investments.fiscal.no_results.copy")}
			</p>
		</div>
	)
}
