import { useState, useEffect, Suspense, ReactNode, ReactElement } from "react"

// SEO
import { Helmet } from "@/lib/seo"

// Router
import { Pages } from "@/misc/routes"

// DateTime
import { DateTime, ms, DEFAULT_TIMEZONE } from "@/lib/dates"
import { apiDateFormat } from "@/misc/constants"

// UI
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"
import { Card, CardBody, CardWrapper } from "@/components/Card"
import { Odometer } from "@/components/Odometer"
import { Heading } from "@/components/Typography"
import {
	FinancialOverviewTable,
	FiscalOverviewByYearContext,
	FiscalOverviewByYearContextType,
} from "./_components/FinancialOverviewTable"
import { TableWarning } from "@/components/table-controls/TableWarning"

// Translations
import { useTrans, Trans } from "@/i18n"
import { useLang } from "@/context/lang"

// Queries
import {
	ProjectTypeEnum,
	useStatisticsMySavingsQuery,
	useStatisticsMySavingsTotalsQuery,
} from "@/api/graphql"

// Icons
import {
	IoBulbOutline,
	IoCarOutline,
	IoTabletPortraitOutline,
	IoLeafOutline,
	IoDiamondOutline,
	IoPizzaOutline,
	IoGlobeOutline,
} from "@/lib/icons"

/**
 * StatisticsMySavings
 * @returns
 */
export function StatisticsMySavings() {
	const t = useTrans(["dashboard", "investments"])

	// State
	const mode: FiscalOverviewByYearContextType = {
		type: "year",
		year: String(DateTime.local().year),
	}

	return (
		<>
			<Helmet>
				<title>{t("dashboard:dashboard.title")}</title>
			</Helmet>
			<PageTemplate
				tabs={
					<>
						<PageTemplateLink
							to={Pages.StatisticsPlatform}
							name={t(
								"common:common.navigation.statistics.platform.link",
							)}
							analyticsId={t(
								"common:common.navigation.statistics.platform.link",
							)}
						/>
						<PageTemplateLink
							to={Pages.StatisticsPlatformEndex}
							name={t(
								"common:common.navigation.statistics.platform.endex.link",
							)}
							analyticsId={t(
								"common:common.navigation.statistics.platform.endex.link",
							)}
						/>
						<PageTemplateLink
							to={Pages.StatisticsMySavings}
							name={t(
								"common:common.navigation.statistics.platform.your_savings.link",
							)}
							analyticsId={t(
								"common:common.navigation.statistics.platform.your_savings.link",
							)}
						/>
					</>
				}
			>
				<div className="mb-8 mt-8 flex justify-center gap-4">
					<div>
						<Heading as="h5" className="mb-2">
							Jij hebt al zoveel duurzame energie geproduceerd!
						</Heading>

						<Suspense
							fallback={
								<span className="dummy-text block w-full animate-pulse rounded-md bg-gray-200"></span>
							}
						>
							<OdometerContainer />
						</Suspense>
					</div>
				</div>

				<Suspense>
					<TotalStats />
				</Suspense>

				<CardWrapper className="col-span-1 mb-4 lg:col-span-4 2xl:col-span-4">
					<YourSavings />
				</CardWrapper>

				{/** Financial overview of current state */}
				<FiscalOverviewByYearContext.Provider value={mode}>
					<CardWrapper>
						<CardBody>
							<Heading
								as="h3"
								styleAs="h6"
								className="mb-4 mr-2 lg:mr-0"
							>
								Jouw ZonneDelen
							</Heading>

							<ReturnsToday />

							<TableWarning
								title={t(
									"investments:investments.fiscal.table.disclaimer.title",
								)}
								message={t(
									"investments:investments.fiscal.table.disclaimer.incomplete_data",
								)}
							/>
							<FinancialOverviewTable />
						</CardBody>
					</CardWrapper>
				</FiscalOverviewByYearContext.Provider>
			</PageTemplate>
		</>
	)
}

/**
 * TotalStats
 * @returns
 */
function TotalStats() {
	const { formatCurrency } = useLang()

	// Query
	const { data } = useStatisticsMySavingsTotalsQuery({
		date: DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.toFormat(apiDateFormat),
	})

	// Template
	return (
		<div className="mb-4 grid grid-cols-4 gap-4">
			<Card>
				<div>
					<dt className="relative mb-1 text-sm font-medium text-gray-500">
						Number of projects
					</dt>
					<dd className="text-sm text-gray-900">
						<Heading as="h2">
							{data?.me?.investment_projects?.results?.length ||
								0}
						</Heading>
					</dd>
				</div>

				<div className="grid grid-cols-2 gap-2">
					<dt className="text-sm font-medium text-gray-500">
						ZonneDelen projecten
					</dt>
					<dd className="mt-1 text-sm text-gray-900">
						{data?.me?.investment_projects?.results?.filter(
							(project) =>
								project?.type === ProjectTypeEnum.SolarShares,
						).length || 0}
					</dd>
					<dt className="text-sm font-medium text-gray-500">
						PRO projecten
					</dt>
					<dd className="mt-1 text-sm text-gray-900">
						{" "}
						{data?.me?.investment_projects?.results?.filter(
							(project) =>
								project?.type === ProjectTypeEnum.Portfolio,
						).length || 0}
					</dd>
				</div>
			</Card>
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					Aantal zonnedelen
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">0</Heading>
				</dd>
			</Card>
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					Aantal panelen
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">0</Heading>
				</dd>
			</Card>

			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					Original total investment amount
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">{formatCurrency(0)}</Heading>
				</dd>
			</Card>
		</div>
	)
}

/**
 * ReturnsToday
 * @returns
 */
function ReturnsToday() {
	const t = useTrans(["investments"])
	const { formatCurrency } = useLang()

	// Query
	const { data } = useStatisticsMySavingsTotalsQuery({
		date: DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.toFormat(apiDateFormat),
	})

	// Template
	return (
		<div className="mb-4 grid grid-cols-4 gap-4">
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					{t(
						"investments:investments.fiscal.hero.returns_today.title",
					)}
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">
						{formatCurrency(
							data?.me?.investor_shares_value_stats
								?.total_investment_value
								? parseFloat(
										data.me.investor_shares_value_stats
											.total_investment_value,
								  )
								: 0,
						)}
					</Heading>
				</dd>
			</Card>
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					Uitbetaalde totaal
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">{formatCurrency(0)}</Heading>
				</dd>
				<div className="grid grid-cols-2 gap-2">
					<dt className="text-sm font-medium text-gray-500">
						Uitbetaalde rente:
					</dt>
					<dd className="mt-1 text-sm text-gray-900">0</dd>
					<dt className="text-sm font-medium text-gray-500">
						Aflossing:
					</dt>
					<dd className="mt-1 text-sm text-gray-900">0</dd>
				</div>
			</Card>
			<Card>
				<dt className="relative mb-1 text-sm font-medium text-gray-500">
					Portfolio rendement
				</dt>
				<dd className="text-sm text-gray-900">
					<Heading as="h2">0%</Heading>
				</dd>
			</Card>
		</div>
	)
}

function OdometerContainer() {
	const { data } = useStatisticsMySavingsQuery()
	const [value, set] = useState(
		data?.me?.investor_production_stats?.total_production_for_counter
			? parseFloat(
					data.me.investor_production_stats
						.total_production_for_counter,
			  )
			: 0,
	)

	useEffect(() => {
		function work() {
			set((value) => {
				return (
					value +
					(data?.me?.investor_production_stats?.production_speed
						? parseFloat(
								data.me.investor_production_stats
									.production_speed,
						  )
						: 0)
				)
			})
		}

		// 10 seconds is the production speed
		const timer = setInterval(work, ms("10s"))
		work()
		return () => {
			clearInterval(timer)
		}
	}, [data?.me?.investor_production_stats?.production_speed])

	return (
		<Odometer
			value={value}
			format="d"
			duration={10000}
			auto={false}
			theme="mijnstroom"
		/>
	)
}

type YourSavingsBlock = {
	id: string
	icon: ReactNode
	text?: ReactElement
	utility: number
}
/**
 * YourSavings
 * @returns
 */
const YourSavings = () => {
	const t = useTrans("project")
	const [savingsBlocks, setSavingsBlocks] = useState<YourSavingsBlock[]>([])

	// Return random savings block
	function getRandomSavingsBlock() {
		const slider_snippet_texts_with_utility_for_kwh = [
			{
				id: "led-light",
				icon: <IoBulbOutline size={40} />,
				utility: 125,
			},
			{
				id: "electric-scooter",
				icon: <IoLeafOutline size={40} />,
				utility: 51.95805,
			},
			{
				id: "tumble-dryer",
				icon: <IoDiamondOutline size={40} />,
				utility: 0.00425532,
			},
			{
				id: "led-household",
				icon: <IoBulbOutline size={40} />,
				utility: 0.01,
			},
			{
				id: "tesla-kms",
				icon: <IoCarOutline size={40} />,
				utility: 6.89655,
			},
			{
				id: "nissan-kms",
				icon: <IoCarOutline size={40} />,
				utility: 7.29927,
			},
			{
				id: "ipad",
				icon: <IoTabletPortraitOutline size={40} />,
				utility: 0.08,
			},
			{
				id: "washing-machine",
				icon: <IoDiamondOutline size={40} />,
				utility: 0.7,
			},
			{
				id: "refrigerator",
				icon: <IoPizzaOutline size={40} />,
				utility: 0.004717,
			},
			{
				id: "gas-free-household",
				icon: <IoGlobeOutline size={40} />,
				utility: 9.52381e-5,
			},
		]
		let random_integer = Math.floor(Math.random() * 9)
		return slider_snippet_texts_with_utility_for_kwh[random_integer]
	}

	// Randomize 3 blocks
	useEffect(() => {
		let savingsBlocks = []
		for (let i = 0; i < 3; i++) {
			let text_with_utility_per_kwh = getRandomSavingsBlock()
			let shares_count = 100 // TODO: Change into actual calculation
			let annual_production = 1000 // TODO: Change into actual calculation
			let utilization = (
				text_with_utility_per_kwh.utility * annual_production
			).toFixed(2)

			savingsBlocks.push({
				...text_with_utility_per_kwh,
				text: (
					<Trans
						ns="project"
						i18nKey={`project.your_savings.block.${text_with_utility_per_kwh.id}.copy`}
						values={{
							shares_count,
							annual_production,
							utilization,
						}}
						components={{
							strong: (
								<span className="font-bold text-gray-900" />
							),
						}}
					/>
				),
			})
		}

		// Set state
		setSavingsBlocks(savingsBlocks)
	}, [])

	return (
		<CardWrapper>
			<CardBody>
				<Heading as="h3" styleAs="h6" className="mb-4 mr-2 lg:mr-0">
					{t("project.your_savings.title")}
				</Heading>
				<div className="mt-4 grid grid-cols-1 gap-4 text-gray-500 md:mt-6 xl:grid-cols-3">
					{savingsBlocks.map((block) => (
						<div
							key={block.id}
							className="col-span-1 flex flex-col items-center rounded-md bg-gray-50 px-4 py-6 lg:py-8"
						>
							<dt>{block.icon}</dt>
							<dd className="mt-2 text-sm">{block.text}</dd>
						</div>
					))}
				</div>
			</CardBody>
		</CardWrapper>
	)
}
