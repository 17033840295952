// React
import { Suspense, useRef } from "react"

// SEO
import { Helmet } from "@/lib/seo"

// Router
import { useParams } from "@/lib/router"

// Translate
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Dates
import { DateTime } from "@/lib/dates"

// Pdf
import { generatePDF, getPdfFileName, PDFViewWrapper } from "@/lib/pdf"

// UI
import { Card, CardWrapper } from "@/components/Card"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"
import { GraphLabels } from "@/components/graphs/GraphLabels"
import { Button } from "@/components/Button"
import { Heading } from "@/components/Typography"
import { ProductionGraph } from "@/components/graphs/types/bar/ProductionGraph"
import { InvestmentsGraphProduction } from "../components/InvestmentsGraphProduction"
import { LoadingScreen } from "@/screens/LoadingScreens"
import { PayoutsTableWeb } from "./_components/PayoutsTableWeb"
import { FinancePerformanceContext } from "@/pages/finance/pages/Performance"
import { ProjectMonthlyProductionTable } from "./_components/ProjectMonthlyProductionTable"

// Icons
import { FiDownload } from "@/lib/icons"

// GraphQL
import { useIsFetching } from "@/lib/query"
import { ProjectTypeEnum, useMonthlyOverviewQuery } from "@/api/graphql"

/**
 * MonthlyOverview
 * @returns
 */
export function MonthlyOverview() {
	// Translate
	const t = useTrans(["investments", "dashboard"])

	// Router
	const params = useParams()

	// Calculate end date
	const endTime = DateTime.fromFormat(String(params.date), "yyyy-MM")

	// Queries
	const { data } = useMonthlyOverviewQuery()
	const isFetching = useIsFetching()

	// Data
	const currentShareValueForSolarShares =
		Number(
			data?.me?.end?.total_investment_value_per_project_type?.find(
				(type) => type?.project_type === ProjectTypeEnum.SolarShares,
			)?.share_value,
		) || 0
	const currentShareValueForSolarBonds = (
		data?.me?.end?.total_investment_value_per_project_type || []
	).reduce((accumulator, type) => {
		let shareValue = 0
		if (
			type?.project_type === ProjectTypeEnum.SolarBonds ||
			type?.project_type === ProjectTypeEnum.Portfolio
		) {
			shareValue = Number(type?.share_value)
		}

		return accumulator + shareValue
	}, 0)

	// PDF
	const pdfViewRef = useRef(null)
	const PDF_TITLE = t("investments:investments.monthly_overview.title", {
		date: endTime.toLocaleString({
			month: "long",
			year: "numeric",
		}),
	})
	const PDF_VIEW_WIDTH = 1024 // // Width that the table renders optimally at
	async function handlePdfDownload() {
		if (pdfViewRef.current) {
			return generatePDF(pdfViewRef.current, {
				filename: getPdfFileName(PDF_TITLE),
				overrideWidth: PDF_VIEW_WIDTH,
				compression: "FAST",
			})
		}
	}

	// Template
	return (
		<>
			<Helmet>
				<title>
					{t("investments:investments.monthly_overview.title", {
						date: endTime.toLocaleString({
							month: "long",
							year: "numeric",
						}),
					})}
				</title>
			</Helmet>

			{/** Intro header */}
			<IntroHeader
				fullName={data?.me?.full_name}
				endTime={endTime}
				handlePdfDownload={handlePdfDownload}
				displayDownloadButton={isFetching === 0}
				totalProjectCount={
					data?.me?.investment_projects?.totalCount || 0
				}
				totalInvestmentValue={
					data?.me?.end?.total_investment_value || "0"
				}
			/>

			{/** Invisible Print view */}
			<PDFViewWrapper
				ref={pdfViewRef}
				title={PDF_TITLE}
				width={PDF_VIEW_WIDTH}
			>
				<IntroHeader
					displayDownloadButton={false}
					fullName={data?.me?.full_name}
					endTime={endTime}
					totalProjectCount={
						data?.me?.investment_projects?.totalCount || 0
					}
					totalInvestmentValue={
						data?.me?.end?.total_investment_value || "0"
					}
				/>
				<OverviewComponents
					currentShareValueForSolarShares={
						currentShareValueForSolarShares
					}
					currentShareValueForSolarBonds={
						currentShareValueForSolarBonds
					}
				/>
			</PDFViewWrapper>

			{/** Visible webview */}
			<OverviewComponents
				currentShareValueForSolarShares={
					currentShareValueForSolarShares
				}
				currentShareValueForSolarBonds={currentShareValueForSolarBonds}
			/>
		</>
	)
}

/**
 * IntroHeader
 * @returns
 */
interface IntroHeaderProps {
	displayDownloadButton?: boolean
	fullName?: string | null
	endTime: DateTime
	handlePdfDownload?: Function
	totalProjectCount: number
	totalInvestmentValue: string
}
function IntroHeader({
	displayDownloadButton = true,
	fullName,
	endTime,
	handlePdfDownload = () => {},
	totalProjectCount,
	totalInvestmentValue,
}: IntroHeaderProps) {
	// Translate
	const t = useTrans(["investments", "dashboard"])
	const { formatCurrency } = useLang()

	// Template
	return (
		<CardWrapper>
			<Card>
				<div className="flex gap-2">
					<Heading as="h2" styleAs="h5">
						{t("investments:investments.monthly_overview.intro", {
							fullName,
							month: endTime.toLocaleString({
								month: "long",
							}),
						})}
					</Heading>

					{/** Download button */}
					{displayDownloadButton === true ? (
						<div className="ml-auto">
							<Button
								onClick={() => {
									handlePdfDownload()
								}}
								aria-label="Download"
								size="small"
							>
								<span className="lg:hidden">
									{t(
										"investments:investments.fiscal.download.mobile_text",
									)}
								</span>
								<span className="hidden lg:inline">
									{t(
										"investments:investments.fiscal.download",
									)}
								</span>
								<FiDownload className="ml-2 h-4 w-4" />
							</Button>
						</div>
					) : null}
				</div>

				<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:mt-6">
					{/** Project Total */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"dashboard:dashboard.stats.platform_stats.blocks.total_projects",
							)}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{totalProjectCount}
						</dd>
					</div>

					{/** Current total value */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"investments:investments.fiscal.hero.returns_today.title",
							)}
						</dt>
						<dd className="mt-1 flex text-sm text-gray-900">
							{formatCurrency(
								totalInvestmentValue
									? parseFloat(totalInvestmentValue)
									: 0,
							)}
						</dd>
					</div>
				</div>
			</Card>
		</CardWrapper>
	)
}

/**
 * OverviewComponents
 * @returns
 */
function OverviewComponents({
	currentShareValueForSolarShares,
	currentShareValueForSolarBonds,
}: {
	currentShareValueForSolarShares: number
	currentShareValueForSolarBonds: number
}) {
	// Translate
	const { formatCurrency } = useLang()
	const t = useTrans("project")

	// Router
	const params = useParams()

	// Calculate start and end dates
	const startTime = DateTime.fromFormat(String(params.date), "yyyy-MM")
	const endTime = DateTime.fromFormat(String(params.date), "yyyy-MM").plus({
		months: 1,
	})

	// Template
	return (
		<>
			<CardWrapper className="mt-8">
				<Card>
					<Heading as="h2" styleAs="h5" className="mb-4">
						{t(
							"investments:investments.monthly_overview.block.shares.title",
						)}
					</Heading>

					{/** Share value */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"investments:investments.monthly_overview.block.shares.value",
							)}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{formatCurrency(currentShareValueForSolarShares)}
						</dd>
					</div>
					<ErrorBoundaryWithErrorState>
						<FinancePerformanceContext.Provider
							value={{
								selectedDateRangeStart: startTime
									.plus({ days: 1 })
									.toJSDate(),
								setSelectedDateRangeStart: () => {},
								selectedDateRangeEnd: endTime.toJSDate(),
								setSelectedDateRangeEnd: () => {},
								graphInterval: "day",
							}}
						>
							<ProductionGraph>
								<ProductionGraph.Actions>
									{null}
								</ProductionGraph.Actions>
								<ProductionGraph.Content>
									<InvestmentsGraphProduction />
								</ProductionGraph.Content>
								<ProductionGraph.Footer>
									<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
										<GraphLabels.TotalProduction />
										<GraphLabels.ExpectedProduction />
									</GraphLabels>
								</ProductionGraph.Footer>
							</ProductionGraph>
						</FinancePerformanceContext.Provider>
					</ErrorBoundaryWithErrorState>
				</Card>
			</CardWrapper>

			<CardWrapper className="mt-8">
				<Card>
					<Heading as="h2" styleAs="h5" className="mb-4">
						{t(
							"investments:investments.monthly_overview.block.bonds.title",
						)}
					</Heading>

					{/** Share value */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"investments:investments.monthly_overview.block.bonds.value",
							)}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{formatCurrency(currentShareValueForSolarBonds)}
						</dd>
					</div>
				</Card>
			</CardWrapper>

			<CardWrapper className="mt-8">
				<Card>
					<Heading as="h2" styleAs="h5" className="mb-4">
						{t("project:project.revenue.all.title")}
					</Heading>

					<Suspense fallback={<LoadingScreen />}>
						<PayoutsTableWeb
							showPagination={false}
							startTime={startTime}
							endTime={endTime}
						/>
					</Suspense>
				</Card>
			</CardWrapper>

			<CardWrapper className="mt-8">
				<Card>
					<Heading as="h2" styleAs="h5" className="mb-4">
						{t(
							"investments:investments.monthly_overview.block.project-production-table.title",
							{
								month: endTime.toLocaleString({
									month: "long",
								}),
							},
						)}
					</Heading>

					<Suspense fallback={<LoadingScreen />}>
						<ProjectMonthlyProductionTable
							startTime={startTime}
							endTime={endTime}
						/>
					</Suspense>
				</Card>
			</CardWrapper>
		</>
	)
}
